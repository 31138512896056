.txt1{
  color: #5e5d5d;
  font-weight: bold;
  font-size: x-large;
  margin-left: 2rem ;
  margin-top: 3rem;
}
.ruler{
    width: 96vw;
    margin-bottom: 3rem;
}

.ruler1 {
    width: 60vw;
    margin-bottom: 3rem;
    margin-right: 41rem;
}

.heading1{
    
    color: #5e5d5d;
  font-weight: bold;
  font-size: x-large;
  margin-left: 2rem ;
  margin-top: 0rem;
}

.main-topic {
    color: #5e5d5d;
    display: flex;
    font-size: 1.7rem;
    clear: both;
    margin-left: 2rem ;
    font-weight: 500;
}

.right-picture{
    float: right;
    height: 12rem;
    margin-right: 2rem;
    width: 12rem;
}
