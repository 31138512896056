.valley-picture{
    float: left;
    height: 12rem;
    margin-right: 2rem;
    width: 12rem;
    margin-bottom: -4rem;
}

.meet1{
    height: 12rem;
    width: 12rem;
    margin-bottom: 3rem
}

.meet2{
    height: 12rem;
    width: 12rem;
    margin-bottom: 3rem
}

.ruler11{
    width: 96vw;
    margin-bottom: 0rem;
}


.topic{
    color: #5e5d5d;
    display: flex;
    font-size: 2rem;
    clear: both;
    margin-left: 2rem ;
    margin-right: 2rem;
    font-weight: 160;
    
}

.txt11{
    color: #5e5d5d;
    font-weight: 250;
    font-size: 2.5rem;
    margin-left: 2rem ;
    margin-top: 3rem;
  }
  .txt12{
    color: #5e5d5d;
    font-weight: 250;
    font-size: 1.5rem;
    margin-bottom: -1rem
   
  }


  .txt13{
    color: #5e5d5d;
    font-weight: 150;
    font-size: 1.5rem;
    
  }

  .column {
    float: left;
    width: 13rem;
    padding: 3rem;
  }
  
  /* Clearfix (clear floats) */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }