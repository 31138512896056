.my-logo-container {
  width: 90%;
  max-width: 100vw;
  height: 8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 0;
  
}

.my-logos {
  flex: 1;
  width: auto;
  height: auto;
  max-height: 8rem;
  
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 767px) {
  .my-logo-container {
      flex-direction: column;
      height: auto;
  }
  .my-logos {
      max-width: 100%;
  }
}

  .flex{
    display: flex; 
    align-items: center;
    justify-content: flex-start;
    margin-left: -2.15rem; 
    flex-wrap: wrap;
    gap: 0.51rem;
    
  }

  .max-w-7xl {
    max-width: 80rem; /* Adjust the value according to your needs */
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media (min-width: 64rem) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    margin-top: 2.5rem; /* Adjust the value according to your needs */
    margin-left: 2.5rem;

  }
  .h-96 {
    height: 20rem; 
    margin-bottom: 2.5rem;
    border-radius: 2rem; /* Adjust the value according to your needs */
  }
  
  .w-20 {
    width: 5rem;
    border-radius: 0.5rem; /* Adjust the value according to your needs */
  }

  .border-2 {
    border-width: 2px;
    border-color: #ED8936;
    opacity: 0.8;
  }
  
  