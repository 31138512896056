

.footer{
    background-color: #2d2d32;
    width: 100%;
    position: absolute;
}

.sb__footer-links-div h4{
    color: white;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
    margin-top: 0px;
    padding: 2rem;
    
}

.sb__footer-copyright{
    color: white;
    font-size: 15px;
}


.social-container {
    background: #eee;
    padding: 25px 50px;
  }



  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

.socialMedia{
text-align: center;
font-size: 25px;
}

  a.social:hover {
    transform: translateY(-2px);
  }

  a.youtube {
    color: #2b7c74;
  }

  a.tiktok {
    color: #2b7c74;
  }
  
  a.facebook {
    color: #2b7c74;
  }
  
  a.twitter {
    color: #2b7c74;
  }
  
  a.instagram {
    color: #2b7c74;
  }