/* Base styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 8%;
}

/* Media Query for Mobile Devices */
@media (max-width: 700px) {
  .App-logo {
    height: 20vmin; /* Adjust logo size for smaller screens */
  }

  .center {
    width: 20%; /* Adjust center content width for smaller screens */
  }
}

/* Animation adjustment for reduced motion */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Logo spin animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
